$(document).ready(initPage);

function initPage(){

    /* Mobile menu */
    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('mobilemenu'),
        'padding': 256,
        'tolerance': 70
    });

    $('.menu-toggler').on('click', function() {
        slideout.toggle();
    });
    $('.panel-overflow').on('click', function() {
        slideout.toggle();
    });

    var fixed = $('.header');

    slideout.on('translate', function(translated) {
      fixed.css( "transform", 'translateX(' + translated + 'px)' );
    });

    slideout.on('beforeopen', function () {
      fixed.css( "transition", 'transform 300ms ease');
      fixed.css( "transform", 'translateX(256px)');
      this.panel.classList.add('panel-open');
      $('html').addClass('html-slideout-open');
    });

    slideout.on('beforeclose', function () {
      fixed.css( "transition", 'transform 300ms ease');
      fixed.css( "transform", 'translateX(0px)');
      this.panel.classList.remove('panel-open');
      $('html').removeClass('html-slideout-open');
    });

    slideout.on('open', function () {
      fixed.css( "transition", '');
    });

    slideout.on('close', function () {
      fixed.css( "transition", '');
    });

    $('.home #mobilemenu li.parent > span').click(function() {
        $(this).parent().toggleClass('active');
    });

    $(".home #mobilemenu a").on('click', function(event) {
      event.preventDefault();      
      slideout.toggle();

      var hash = this.hash;
      var top = $(hash).offset().top - 40;
      $('html, body').animate({
        scrollTop: top
      }, 500, function(){
      });
    });

    /* End of Mobile menu */

    /* Desktop menu */
    $(".home .topmenu a, .home .footer-menu a, .home-intro-block a.btn, .home-bnr-block a.btn").on('click', function(event) {

      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;

        var top = $(hash).offset().top - 40;

        $('html, body').animate({
          scrollTop: top
        }, 500, function(){
          //window.location.hash = hash;
        });
      }

    });


    /*Checkout*/


    $(document).on('click', '#billing-address-check', function() {
      if(this.checked) {
        $('#billing-address-section').addClass('hidden');
      }
      else {
        $('#billing-address-section').removeClass('hidden');
      }
    });
  

}
var lastScrollTop = 0;
window.addEventListener("scroll", function(){ 
   var st = window.pageYOffset || document.documentElement.scrollTop;
   if (st > lastScrollTop){
      // downscroll code
      $('.header').addClass('hidden');
   } else {
      // upscroll code
      $('.header').removeClass('hidden');
   }
   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, false);